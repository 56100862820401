import { nextTick } from 'vue'
import { createI18n } from 'vue-i18n'

//unfortunately, the only way to use i18n outside components is through a separate helper file but
//I need to initialize i18n in main.ts so this assignment is immediately replaced with a call to 
//[[setupI18N]] by main.ts but it fixes typing errors...
export let i18n = createI18n({ locale: 'en' });


export function setupI18n(options:any = { locale: 'en' }) {
  i18n = createI18n(options) as any; //seems like typings are too complicated and cannot be assigned directly
  setI18nLanguage(i18n, options.locale)
  return i18n
}

export function setI18nLanguage(i18n: any, locale: string) {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale
  } else {
    i18n.global.locale.value = locale
  }
  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
  document.querySelector('html')?.setAttribute('lang', locale)
}

export async function loadLocaleMessages(i18n:any, locale:string) {
  // load locale messages with dynamic import
  const messages = await import(
    /* webpackChunkName: "locale-[request]" */ `../locales/${locale}/translation.json`
  )

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages.default)

  return nextTick()
}