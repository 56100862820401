import { createApp } from 'vue'
import App from './App.vue'
import router from './helpers/router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';



import { Api } from './services/api';
import { loadLocaleMessages, setupI18n } from './helpers/i18n';
import { createPinia } from 'pinia';
import { SkillService } from './services/skills';
import {
  di_Api, di_IAllocationService, di_IContractService, di_ICounterService,
  di_ISkillService, di_ITeamService, di_ITrainingService, di_IAppConfigService
} from './helpers/injection';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import duration from 'dayjs/plugin/duration';
import { ContractService } from './services/contracts';
import { CounterService } from './services/counters';
import { TeamService } from './services/teams';
import { TrainingService } from './services/trainings';
import { AllocationService } from './services/allocations';
import { AppConfigService } from './services/app-config';


//initialize dayjs
dayjs.extend(localizedFormat);
dayjs.extend(duration);

//create app
const app = createApp(App)
  .use(IonicVue)
  .use(createPinia())
  .use(router);

//DI 
const api = Api.instance;
api.init(process.env.VUE_APP_API_URL);
app.provide(di_Api, api);
app.provide(di_ISkillService, new SkillService());
app.provide(di_IContractService, new ContractService());
app.provide(di_ICounterService, new CounterService());
app.provide(di_ITeamService, new TeamService());
app.provide(di_ITrainingService, new TrainingService());
app.provide(di_IAllocationService, new AllocationService());
app.provide(di_IAppConfigService, new AppConfigService());

//wait for router ready and config+mount the app
router.isReady().then(async () => {
  document.title = 'PeopleForce';
  const locale = await new AppConfigService().defaultLocale() || 'fr';
  //TODO GMA can have typesafe locale files https://vue-i18n.intlify.dev/guide/advanced/typescript.html#global-resource-schema-type-definition
  const i18n = setupI18n({
    legacy: false,
    locale: locale, // set locale
    fallbackLocale: 'en', // set fallback locale
  });

  await loadLocaleMessages(i18n, locale);
  await loadLocale(locale);
  app.use(i18n)
  app.mount('#app');
});




//existing locales. Will be lazy loaded on demand and can come from nodejs or from locales folder
//dayjs locale files have to be converted to typescript
const locales = {
  fr: () => import('dayjs/locale/fr'),
  en: () => import('dayjs/locale/en'),
}

function loadLocale(language: string) {
  (locales as any)[language]().then(() => dayjs.locale(language))
}