import { IAppConfigService, MenuConfiguration } from './interfaces';
import {Api} from './api';

/**
 * @implements IAppConfigService
 */
export class AppConfigService implements IAppConfigService {
    /**
     * @implements IAppConfigService.defaultLocale
     */
    async defaultLocale(): Promise<string> {
        return Api.instance
            .get(`appconfig/defaultlocale`, false);
    }
    /**
     * @implements IAppConfigService.getMenuConfig
     */
    async getMenuConfig(): Promise<MenuConfiguration> {
        return Api.instance
            .get(`appconfig/menuConfiguration`);
    }
}