import { ISkillService, Skill, SkillCategory } from './interfaces';
import { Api } from './api';



/**
 * @implements ISkillService.byPersonnel
 */
export class SkillService implements ISkillService {

    /**
     * @implements ISkillService.getCategoriesFromDate
     */
    async getCategoriesFromDate(personnelId: number, date: Date): Promise<SkillCategory[]> {
        return Api.instance
            .get(`skills/CategoriesFromDate/${personnelId}/${date.toISOString()}`);
    }

    /**
     * @implements ISkillService.getCategory
     */
    async getCategory(id: string): Promise<SkillCategory> {
        return Api.instance
            .get(`skills/category/${id}`);
    }

    /**
     * @implements ISkillService.getByCategory
     */
    async getByCategory(businessPropertyTypeId: string, personnelId: number): Promise<Skill[]> {
        return Api.instance
            .get(`skills/GetByCategory/${businessPropertyTypeId}/${personnelId}/${new Date().toISOString()}`)
            .then((x: any[]) => x.map(o => this.mapSkill(o)));
    }


    mapSkill(o: any): Skill {
        return {
            id: o.id,
            name: o.name,
            type: o.type,
            start: new Date(o.start),
            end: new Date(o.end),
            value: o.value,
            state: o.state,
            disabled: o.disabled
        };
    }
}