import { Api } from '@/services/api';
import { User } from '@/services/interfaces';
import { defineStore } from 'pinia';
import { useAuthStore } from './auth.store';

/**
 * user store
 */
export const useUserStore = () => {
    //Wrap the store in a closure so it initializes the store before returning it
    const innerStore = defineStore({
        id: "user",
        /**
         * contains the user model (null if not logged)
         */
        state: () => ({
            user: null as User|null,
        }),

        actions: {
            /**
             * Load user details from a personnel id
             * The call use the current time to get the contracts of the user timed on the present
             * @param personnelId id of personnel to get user details from 
             */
            async load(personnelId: number) {
                const u = await Api.instance
                    .get(`personnel/details/${personnelId}/${new Date().toISOString()}`);
                this.user = u;
            },
        },
    });

    //reload the information if logged in but not filled yet (checking the localstorage)
    const s = innerStore();
    const auth = useAuthStore();
    if (auth.userId >= 0 && !s.user) {
        s.load(auth.userId);
    }
    return s;
};