import { InjectionKey } from "vue";
import { Api } from "../services/api";
import { IAllocationService, IAppConfigService, IContractService, ICounterService, ISkillService, ITeamService, ITrainingService } from "../services/interfaces";

/*
This is where all injectable services references should be stored
*/
export const di_ISkillService = Symbol() as InjectionKey<ISkillService>;
export const di_IContractService = Symbol() as InjectionKey<IContractService>;
export const di_ICounterService = Symbol() as InjectionKey<ICounterService>;
export const di_ITeamService = Symbol() as InjectionKey<ITeamService>;
export const di_ITrainingService = Symbol() as InjectionKey<ITrainingService>;
export const di_IAllocationService = Symbol() as InjectionKey<IAllocationService>;
export const di_IAppConfigService = Symbol() as InjectionKey<IAppConfigService>;
export const di_Api = Symbol() as InjectionKey<Api>;