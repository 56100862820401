import { ITrainingService, Training } from './interfaces';
import { Api } from './api';


/**
 * @implements ITrainingService
 */
export class TrainingService implements ITrainingService {
    /**
     * @implements ITrainingService.byPersonnel
     */
    async byPersonnel(personnelId: number): Promise<Training[]> {
        return Api.instance
            .get(`trainings/ByPersonnel/${personnelId}`)
            .then((x: any[]) => x.map(o => this.mapTraining(o)));
    }

    /**
     * @implements ITeamService.hasLicense
     */
    async hasLicense(): Promise<boolean> {
        return Api.instance
            .get('trainings/HasLicense');
    }

    mapTraining(o: any): Training {
        return {
            id: o.id,
            name: o.name,
            start: new Date(o.start),
            end: new Date(o.end),
            domain: o.domain,
            state: o.state,
            baseStatus: o.baseStatus,
            color: o.color,
        };
    }
}