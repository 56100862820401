import { ITeamService, Team } from './interfaces';
import { Api } from './api';


/**
 * @implements ITeamService
 */
export class TeamService implements ITeamService {
    /**
     * @implements ITeamService.byPersonnel
     */
    async byPersonnel(personnelId: number): Promise<Team[]> {

        return Api.instance
            .get(`teams/ByPersonnel/${personnelId}`)
            .then((x: any[]) => x.map(o => this.mapTeam(o)));
    }

    mapTeam(o: any): Team {
        return {
            id: o.id,
            name: o.name,
            start: new Date(o.start),
            end: new Date(o.end),
            color: o.color
        };
    }
}