import { Counter, ICounterService } from './interfaces';
import { Api } from './api';


/**
 * @implements ICounterService
 */
export class CounterService implements ICounterService {

    /**
     * @implements ICounterService.byPersonnel
     */
    async byPersonnel(personnelId: number): Promise<Counter[]> {
        const atDate = new Date();
        return Api.instance
            .get(`counters/ByPersonnel/${personnelId}/${atDate.toISOString()}`)
    }
}