import dayjs from "dayjs";
import {i18n} from '@/helpers/i18n';

/**
 * Get date range display
 * ranges can cover 4 cases:
 * - 2 invalid dates
 * - start date is invalid
 * - end date is invalid
 * - both dates are valid
 * @param from the range start date
 * @param to the range end date
 * @param format the dayjs format to use for the date display
 * @returns a string representation of the range
 */
export function rangeDateText(from: Date, to: Date, format = 'L'): string {
    if (from.getTime() <= 0 && to.getTime() <= 0) {
        return '';

    } else if (from.getTime() <= 0) {
        return i18n.global.t('g.date.until', {date: dayjs(to).format(format)});

    } else if (to.getTime() <= 0) {
        return i18n.global.t('g.date.since', {date: dayjs(from).format(format)});

    } else {
        return i18n.global.t('g.date.range', {since: dayjs(from).format(format), until: dayjs(to).format(format)});
    }
}

/**
 * Get time range display
 * ranges can cover 4 cases:
 * - 2 invalid dates
 * - start date is invalid
 * - end date is invalid
 * - both dates are valid
 * @param from the range start date
 * @param to the range end date
 * @param format the dayjs format to use for the date display
 * @returns a string representation of the range
 */
export function rangeTimeText(from: Date, to: Date, format = 'H:mm'): string {
    if (from.getTime() <= 0 && to.getTime() <= 0) {
        return '';

    } else if (from.getTime() <= 0) {
        return i18n.global.t('g.time.until', {date: dayjs(to).format(format)});

    } else if (to.getTime() <= 0) {
        return i18n.global.t('g.time.since', {date: dayjs(from).format(format)});

    } else {
        return i18n.global.t('g.time.range', {since: dayjs(from).format(format), until: dayjs(to).format(format)});
    }
}

/**
 * Get a string ISO8601 date representation applying the timezone offset
 * 
 * This is necessary because of an inconsistency between JS date parsing from ISO ('unapply' the TS offset)
 * and toISOString() (displays a TZ unapplied) vs C# which parses and returns a string with TZ applied
 * @see https://stackoverflow.com/a/49332027/1254877
 * @param d the date to display
 * @returns a string with a localized ISO8601 date representation
 */
export function toISOLocal(d: Date) {
    const z  = (n:number) =>  ('0' + n).slice(-2);
    const zz = (n:number) => ('00' + n).slice(-3);
  
    return d.getFullYear() + '-'
           + z(d.getMonth()+1) + '-' +
           z(d.getDate()) + 'T' +
           z(d.getHours()) + ':'  + 
           z(d.getMinutes()) + ':' +
           z(d.getSeconds()) + '.' +
           zz(d.getMilliseconds());
  }