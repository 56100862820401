import { defineStore } from 'pinia';
import { Api } from '@/services/api';

/**
 * authentication store
 */
export const useAuthStore = defineStore({
    id: 'auth',
    /**
     * get the idToken if present
     */
    state: () => {
        const token = localStorage.getItem('id_token');
        return {
            // initialize state from local storage to enable user to stay logged in
            idToken: isValidJwt(token) ? token : null,
            returnUrl: null as string | null
        }
    },
    getters: {
        /**
         * check if user is logged
         */
        isLogged: (state) => state.idToken != null,
        /**
         * get logged user claims
         */
        claims: (state) => state.idToken ? JSON.parse(atob(state.idToken.split('.')[1])) : null,
        /**
         * get user id from claims
         */
        userId(): number { return this.claims ? this.claims['user_id'] : -1 },

    },
    actions: {
        /**
         * identify a user from its identifier
         * The identifier in this case is a phone nb. This step must come before the call to [[login]]
         */
        identify(id: string): Promise<IdentificationResult> {
            return Api.instance
                .post('Authenticate/identify', id)
                .then(x => x.json());
        },
        /**
         * Log user in
         * This step comes after the call to [[identify]]. The idtoken is saved in the state and localstorage as part of
         * this execution
         * @param code OTC to log the user in 
         * @param personnelId personnel id
         * @returns an idToken if the login is succesful
         */
        async login(code: number, personnelId: number) {
            const result = await Api.instance
                .post('Authenticate/login', { code, personnelId })
                .then(x => x.json());

            if (result) {
                localStorage.setItem('id_token', result.token);
                this.idToken = result.token;
            }

            return result;
        },
        /**
         * log the current user out and redirect to root page
         */
        logout() {
            this.$reset();
            localStorage.removeItem('id_token');
            window.location.href = "";
        }
    }
});

/**
 * an identification result from the backend
 */
interface IdentificationResult {
    status: 0|1;    //1 if identification succesfull, 0 otherwise
    message: string;
    personnelId:number|undefined; //present if succesfull, undefined otherwise
}

/**
 * Check the validity of an idToken
 * This only checks the expiration value
 * @param token the token to validate
 * @returns a flag indicating if the token is valid (false if not oken)
 */
function isValidJwt(token:string|null): boolean {
    if (!token) {
        return false;
    }
    const split = token.split('.');
    if (split.length != 3) {
        return false;
    }
    const idToken = JSON.parse(atob(split[1]));
    if (!idToken || !idToken.exp || !Number.isInteger(idToken.exp)) {
        return false;
    }
    
    return (idToken.exp * 1000) > Date.now();
}