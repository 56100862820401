/**
 * Get the foreground color with a valid contrast for its background
 * @see https://graphicdesign.stackexchange.com/a/77747/177521
 * @param bg 6 digits hex color value with leading # character
 */
export function getForegroundForBackground(bg: string): string {
    const displayGamma = 2.2; //typical value but could be approximated to 1 probably
    const rgb = {r: parseInt(bg.slice(1, 3), 16) / 255 * displayGamma, 
                g: parseInt(bg.slice(3, 5), 16) / 255 * displayGamma, 
                b: parseInt(bg.slice(5, 7), 16) / 255 * displayGamma};
    return (0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b > 0.5 * displayGamma) ? '#000': '#fff';
}