import { Contract, IContractService } from './interfaces';
import { Api } from './api';


/**
 * @implements IContractService
 */
export class ContractService implements IContractService {

    /**
     * @implements IContractService.byPersonnel
     */
    async byPersonnel(personnelId: number): Promise<Contract[]> {

        return Api.instance
            .get(`contracts/ByPersonnel/${personnelId}`)
            .then((x: any[]) => x.map(o => this.mapContract(o)));
    }


    mapContract(o: any): Contract {
        return {
            id: o.id,
            type: o.type,
            start: new Date(o.start),
            end: new Date(o.end),
            duration: o.duration,
            state: o.state,
            color: o.color
        };
    }
}